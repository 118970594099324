import { Link } from "gatsby";
import {
    Box, Image, Text, TextInput
} from "grommet";
import React, { useState } from "react";
import PrimaryButton from "../components/elements/primary_button";
import StandardText from "../components/elements/standard_text";
import Layout from "../components/layout";
import mainLogo from "../images/logo.avif";
import service from '../service/login_service';

const ForgotPasswordPage = ({ logo }) => {

    const [username, setUserName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [successful, setSuccessful] = useState(false);

    const sendEmail = () => {
        if (!username || !username.includes('@') || !username.includes('.') || username.length < 6) {
            setError('Identifiant non valid'); return;
        }

        setError('');
        setLoading(true);
        service.forgotPassword(username)
            .then(_ => {
                setSuccessful(true);
            }).catch(err => {
                setLoading(false);
                const message = err.response.data?.error?.message;
                setError(message || 'Unexpected error');
                console.log(err.response);
            });
    }

    const inputStyle = { background: '#EDEDED', fontSize: 16, borderRadius: 10, border: 'none' };
    return (
        <Layout style={{ justifyContent: "center", alignContent: "center" }}
        >
            <Box
                justify="center"
                as="main"
                flex
                overflow="auto"
                width="large"
                direction="column"
                alignContent="center"
                alignSelf="center"
            >
                <Box width="medium" alignSelf="center" justify="center" alignContent="center" gap="medium">
                    <Image src={logo || mainLogo} />

                    <Text size="small" style={{ color: '#dc3545' }} textAlign="center">{error}</Text>
                    {successful ?
                        <Box justify="center" align="center" gap="medium">
                            <StandardText
                                style={{fontWeight: 'bold'}}
                                textAlign='center'
                                label={"If your account exists, a password resetting email will be sent to your email."}
                            />
                            <Box width="large" align="center" justify="center">
                                <Link to="/login">
                                    <Text style={{ color: '#707070', fontSize: 14 }} textAlign="center">Connection</Text>
                                </Link>
                            </Box>
                        </Box> :
                        <Box width="large" gap="small">
                            <TextInput
                                placeholder="Email "
                                onChange={event => setUserName(event.target.value)}
                                style={inputStyle}
                            />
                            <Box width="small" alignSelf="center">
                                <PrimaryButton
                                    onClick={sendEmail} disabled={loading}
                                    label="Send email" color='#fff'
                                />
                            </Box>
                            <Box width="large" align="center" justify="center">
                                <Link to="/login">
                                    <Text style={{ color: '#707070', fontSize: 14 }} textAlign="center">Connection</Text>
                                </Link>
                            </Box>
                        </Box>
                    }
                </Box>

            </Box>
        </Layout>
    );
}

export default ForgotPasswordPage;